import React from "react";

export default function Pricing() {
  return (
    <div>
      <section className="pricing-section section-b-space text-start">
        <div className="container">
          <div className="title-horizontal">
            <h2>
              Unleash the power of automated trading & achieve huge income
            </h2>
            <p>
              Select one of the BotVerse BOT from the list below to get started
              with. Each has its own benefits and capabilities to ensure
              profitability in live markets. Set your capital deployment and
              start trading today!
            </p>
          </div>
          <div className="row pricing-row g-xl-5 g-4 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box">
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/hanging.svg"
                  className="img-fluid handing-cls"
                  alt="effect"
                />
                <div className="pricing-top">
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/pricing-top.svg"
                    className="img-fluid pricing-bg"
                    alt=""
                  />
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/weekly.svg"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>Beginner Bot</h3>
                </div>
                <div className="pricing-mid">
                  <div className="clip-path-content">
                    <div>
                      <h2>$25</h2>
                      {/* <h4>/ only</h4> */}
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <div className="clip-path-content">
                    <ul>
                      <li className="mb-2">
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        />
                        <h4>Rewards</h4>
                      </li>
                      <li className="ms-5">
                        {/* <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        /> */}
                        <p className="ms-2">0.30%</p>
                      </li>
                    </ul>
                    <a className="pricing-link" data-cursor="pointer">
                      {/* Choose plan */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="pricing-box">
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/hanging-green.svg"
                  className="img-fluid handing-cls"
                  alt="effect"
                />
                <div className="pricing-top">
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/pricing-center.svg"
                    className="img-fluid pricing-bg"
                    alt=""
                  />
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/monthly.svg"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>BOT 2</h3>
                </div>
                <div className="pricing-mid">
                  <div className="clip-path-content">
                    <div>
                      <h2>$50</h2>
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <div className="clip-path-content">
                    <ul>
                      <li className="mb-2">
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        />
                        <h4>Estimated Daily Profit</h4>
                      </li>
                      <li className="ms-5">
                    
                        <p className="ms-2">0.5 to 1%</p>
                      </li>
                      <li className="mb-2">
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        />
                        <h4>Capping</h4>
                      </li>
                      <li className="ms-5">
                        <h4 className="ms-3">2x</h4>
                      </li>
                    </ul>
                    <a className="pricing-link" data-cursor="pointer">
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box">
                <img
                 src="https://themes.pixelstrap.net/mega_bot/assets/svg/hanging-green.svg"  
                  className="img-fluid handing-cls"
                  alt="effect"
                />
                <div className="pricing-top">
                  <img
                   src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/pricing-center.svg"
                    className="img-fluid pricing-bg"
                    alt=""
                  />
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/yearly.svg"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>Intermediate Bot</h3>
                </div>
                <div className="pricing-mid">
                  <div className="clip-path-content">
                    <div>
                      <h2>$100</h2>
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <div className="clip-path-content">
                    <ul>
                      <li className="mb-2">
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        />
                        <h4>Rewards</h4>
                      </li>
                      <li className="ms-5">
                      
                        <p className="ms-2">0.40% </p>
                      </li>
                    
                    </ul>
                    <a className="pricing-link" data-cursor="pointer">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box">
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/hanging.svg"
                  className="img-fluid handing-cls"
                  alt="effect"
                />
                <div className="pricing-top">
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/pricing-top.svg"
                    className="img-fluid pricing-bg"
                    alt=""
                  />
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/pricing/yearly.svg"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>Professional Bot</h3>
                </div>
                <div className="pricing-mid">
                  <div className="clip-path-content">
                    <div>
                      <h2>$500</h2>
                      {/* <h4>/ only</h4> */}
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <div className="clip-path-content">
                    <ul>
                      <li className="mb-2">
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        />
                        <h4>Rewards</h4>
                      </li>
                      <li className="ms-5">
                        {/* <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/arrow.svg"
                          className="img-fluid"
                          alt="arrow"
                        /> */}
                        <p className="ms-2">0.50%</p>
                      </li>
                    
                    </ul>
                    <a className="pricing-link" data-cursor="pointer">
                      {/* Choose plan */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
