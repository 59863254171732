import React from "react";

export default function Main() {
  return (
    <div className=" section-b-space text-start">
      <div className="container">
        <div class="title-basic">
          <h2>How to get started with BotVerse?</h2>
        </div>
        <div class=" text-start">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="step my-2">
                <div class="step-number">1</div>
                <div class="step-text text-white">Purchase Bot</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="step my-2">
                <div class="step-number ">2</div>
                <div class="step-text text-white">Add Liquidity</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="step my-2">
                <div class="step-number">3</div>
                <div class="step-text text-white">Activate Trading</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="step my-2">
                <div class="step-number">4</div>
                <div class="step-text text-white">Monitor Performance</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
