import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import Header from "../Coman/Header";
import { ProjectContext } from "../../ProjectContext";
import axios from 'axios'
import Pagination from "../Coman/Pagination";

export default function Withdrawal() {
  const {
    account,
    balance,
    toastSuccess,
    toastError,
    getudata,
    tokenrate,
    dbuser, dbbalance,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)

  const [amount, setamount] = useState(0);
  const [rcv_amount, setrcv_amount] = useState(0);
  const [fees, setfees] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [token_rate, setToken_rate] = useState(1);

  const getDataPending = async () => {
    if (!account) {
      return;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        // address: "default",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getDataPending()
  }, [account]);

  const withdraw = async () => {
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }

    if (amount < 25) {
      toastError("Min $25 required");
      settrdone(false);
      return;
    }
    if (Number(amount) > dbuser.balance) {
      toastError("Insufficient Balance");
      settrdone(false);
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        address: account,
        amount: amount,
        token: total_tokens,
        fee: fees,//admin
        t_rcv: rcv_amount,
        rate: token_rate,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
      });
    getuserDB();
    getDataPending()
    getData()
    settrdone(false);
  }
  useEffect(() => {
    var tmt = amount / token_rate;
    var tmtfee = (tmt * 5) / 100;
    settotal_tokens(tmt);
    setfees(tmtfee);
    setrcv_amount(tmt - tmtfee);
  }, [amount, token_rate]);


  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img px-md-5 px-1">
                <h2 className="text-white mb-5 text-center mt-3">
                  Withdrawal
                </h2>

                <div className="row justify-content-center mt-0 mt-md-5 mb-5">
                  <div className="col-lg-5  col-md-10 col-sm-12 col-12 mt-3">
                    <div className="step-1 py-4">
                      <div
                        className="step-text text-white mt-3"
                        style={{ position: "relative", zIndex: "999" }}
                      >
                        <div className="auth-form mx-4">
                          <div className="mb-3 form-group text-start  ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="password" className="form-label">
                                Withdrawal
                              </label>
                              <label htmlFor="password" className="form-label">
                                Balance: ${dbuser ? dbuser.balance : 0}
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter amount"
                              className="form-control ps-2" value={amount}
                              onChange={(e) =>
                                setamount(e.target.value)
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Fees</p>
                            <p>$ {fees} (5%)</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Total Recieve</p>
                            <p>$ {rcv_amount}</p>
                          </div>
                          <button className="btn-solid w-100 text-center mt-5" onClick={() => withdraw()}>
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {datap.length ?
                  <div className="px-md-5 px-1 mt-0 mt-md-5">
                    <h3 className="text-white">Pending Withdrawal</h3>
                    <div style={{ overflow: "auto hidden" }}>
                      <div className="table-main">
                        <div className="tbl-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-black"> #</th>
                                <th className="text-black">Date</th>
                                <th className="text-black">Amount</th>
                                <th className="text-black">Fee</th>
                                <th className="text-black">Received Amount</th>
                                <th className="text-black">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="tbl-content">
                          <tbody>
                            {datap.map((ele, i) => {
                              return (
                                <tr>
                                  <th> {ele.id}</th>
                                  <th>{ele.createdAt}</th>
                                  <th>$ {ele.amount} </th>
                                  <th>${ele.fee}</th>
                                  <th>${ele.t_rcv}</th>
                                  <th>{ele.createdAt}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tbody />
                        </div>
                      </div>
                    </div>
                  </div> : ""}
                <div className="px-md-5 px-1 mt-0 mt-md-5">
                  <h3 className="text-white">Withdrawal</h3>
                  <div style={{ overflow: "auto hidden" }}>
                    <div className="table-main">
                      <div className="tbl-header">
                        <table>
                          <thead>
                            <tr>
                              <th className="text-black"> #</th>
                              <th className="text-black">Date</th>
                              <th className="text-black">Amount</th>
                              <th className="text-black">Fee</th>
                              <th className="text-black">Received Amount</th>
                              <th className="text-black">Status</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="tbl-content">
                        <tbody>
                          {data.map((ele, i) => {
                            return (
                              <tr>
                                <th> {ele.id}</th>
                                <th>{ele.createdAt}</th>
                                <th>$ {ele.amount} </th>
                                <th>${ele.fee}</th>
                                <th>${ele.t_rcv}</th>
                                <th>{ele.createdAt}</th>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tbody />
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pages={pages}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
