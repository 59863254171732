import React from "react";

export default function Unleash() {
  return (
    <div>
      <section className="feature-section section-b-space">
        <div className="bg-effect">
          <img
            src="../assets/images/feature.gif"
            className="img-fluid feature-left"
            alt=""
          />
          <img
            src="../assets/images/feature.gif"
            className="img-fluid feature-right"
            alt=""
          />
          <img
            src="../assets/images/feature-bg.png"
            className="img-fluid feature-bg"
            alt=""
          />
          <span className="round-effect" />
        </div>
        <div className="container">
          <div className="title-basic">
            <h2>
              BotVerse Fetches & Identifies Profitability By Executing Accurate
              Trades
            </h2>
          </div>
          <div className="swiper featureSlider">
            <div id="featureSlider" className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row g-xxl-5 g-4">
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/market_reading.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/market_reading_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0"> Market Reading</h3>
                      </div>
                      <h4>
                        Contrantly reading market data & looking for potential
                        trades.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/technical_analysis.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/technical_analysis_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Technical Analysis</h3>
                      </div>
                      <h4>
                        Analyzing asset markets from the technical perspective.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top ">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/trend_identification.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/trend_identification_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Trend Identification</h3>
                      </div>
                      <h4>
                        Identifying market trends by analyzing the hourly
                        candlestick.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/arbitrage_findings.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/arbitrage_findings_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Arbitrage Findings</h3>
                      </div>
                      <h4>
                        Looking for arbitrage between two markets for identical
                        assets.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/high_frequency_trading.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/high_frequency_trading_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">High-Frequency Trading</h3>
                      </div>
                      <h4>
                        Benefiting with the speed of blockchain with
                        high-frequency trading.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/algorithmic_trading.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/algorithmic_trading_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Algorithmic Trading</h3>
                      </div>
                      <h4>
                        Trading markets with algorithmic methods & historical
                        data.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/token_sniping.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/token_sniping_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Token Sniping</h3>
                      </div>
                      <h4>
                        Snipping new token launches & benefiting as early
                        investors.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="feature-box">
                      <div className="feature-top">
                        <div className="feature-icon">
                          <img
                            src="assets/images/img/svg-1/copy_trading.svg"
                            className="img-fluid outline-icon"
                            alt=""
                          />
                          <img
                            src="assets/images/img/svg-1/copy_trading_01.svg"
                            className="img-fluid bold-icon"
                            alt=""
                          />
                        </div>
                        <h3 className="p-0">Copy Trading</h3>
                      </div>
                      <h4>
                        Copying trading strategy from market leading traders
                        from exchanges.
                      </h4>
                      <div data-cursor="pointer" className="link-overflow">
                        {/* <a href="service.html">
                          Read more{" "}
                          <i className="iconsax" data-icon="arrow-right" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pagination-effect">
          <img
            src="https://themes.pixelstrap.net/mega_bot/assets/svg/pagination-robot.svg"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="swiper-pagination feature-pagination" /> */}
      </section>
    </div>
  );
}
