import React from "react";

export default function Creators() {
  return (
    <div>
      <div className="service-section section-b-space text-start">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="row g-4 service-row">
                <div className="col-sm-6">
                  <div className="service-box">
                    <div className="service-icon">
                      <img
                        src="assets/images/img/svg/easy_integration.svg"
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/img/svg/easy_integration_01.svg"
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h3>
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/service-title.svg"
                          alt="effect"
                          className="img-fluid"
                        />
                        Easy Integration
                      </h3>
                      <p>
                        BotVerse offers seamless integration with popular
                        cryptocurrency exchanges, enabling users to set up their
                        trading bots quickly without any technical hassles.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="service-box">
                    <div className="service-icon">
                      <img
                        src="assets/images/img/svg/multi_exchange_support.svg"
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/img/svg/multi_exchange_support_01.svg"
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h3>
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/service-title.svg"
                          alt="effect"
                          className="img-fluid"
                        />
                        Multi-exchange Support
                      </h3>
                      <p>
                        Users can manage trades across multiple exchanges
                        simultaneously, maximizing market exposure and
                        efficiency with BotVerse’s multi-exchange compatibility.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="service-box">
                    <div className="service-icon">
                      <img
                        src="assets/images/img/svg/advanced_algorithms_01.svg"
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/img/svg/advanced_algorithms.svg"
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h3>
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/service-title.svg"
                          alt="effect"
                          className="img-fluid"
                        />
                        Advanced Algorithms
                      </h3>
                      <p>
                        Leveraging cutting-edge algorithms, BotVerse ensures
                        precise market analysis and optimal trade execution for
                        both beginner and experienced traders.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="service-box">
                    <div className="service-icon">
                      <img
                        src="assets/images/img/svg/user_friendly_interface_01.svg"
                        className="img-fluid outline-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/img/svg/user_friendly_interface.svg"
                        className="img-fluid bold-icon"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h3>
                        <img
                          src="https://themes.pixelstrap.net/mega_bot/assets/svg/service-title.svg"
                          alt="effect"
                          className="img-fluid"
                        />
                        User-friendly Platform
                      </h3>
                      <p>
                        Designed with simplicity in mind, BotVerse provides a
                        clean and intuitive interface, making it easy for users
                        to configure bots, track performance, and manage their
                        strategies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-info">
                <div>
                  <div className="title">
                    <h2>Trading is now accessible for Everyone</h2>
                    <h3>
                      Say goodbye to old methods of trading, BotVerse is here to
                      overcome all inefficiencies!
                    </h3>
                  </div>
                  <p>
                    BotVerse offers tailored trading solutions to meet needs for
                    different trading strategies while offering efficiency,
                    speed, and accuracy in executing trades across various
                    markets.
                  </p>
                  {/* <a data-cursor="pointer" className="btn-arrow" href="#">
                    <div className="icon-arrow">
                      <i className="iconsax" data-icon="arrow-up" />
                    </div>
                    Demo
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
