import React from "react";

export default function Contact() {
  return (
    <div>
      <section className="info-section section-b-space">
        <div className="container">
          <div
            className="info-box aos-init aos-animate"
            data-aos="fade-in"
            data-aos-duration={1000}
            data-aos-delay={100}
          >
            <div className="hand-effect d-md-block d-none">
              <img
                src="https://themes.pixelstrap.net/mega_bot/assets/svg/hand.svg"
                className="img-fluid left-hand"
                alt="hand"
              />
              <img
                src="https://themes.pixelstrap.net/mega_bot/assets/svg/hand.svg"
                className="img-fluid right-hand"
                alt="hand"
              />
            </div>
            <h2>
            Empowering Traders To 
              <span>
              Achieve Profit Accuracy
                {/* <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/title-effect.svg"
                  className="img-fluid"
                  alt="title-effect"
                /> */}
              </span>
            </h2>
            <p>
            BotVerse empowers traders with the tools they need to automate and optimize their trading strategies across multiple exchanges, simplifying the process while enhancing results.
                  
            </p>
            <ul>
              <li>
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                  className="img-fluid"
                  alt="tick"
                />
               Safe 
              </li>
              <li>
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                  className="img-fluid"
                  alt="tick"
                />
               Secure 
              </li>
              <li>
                <img
                  src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                  className="img-fluid"
                  alt="tick"
                />
               Simple 
              </li>
             
            </ul>
            <a data-cursor="pointer" className="btn-arrow" href="#">
              <div className="icon-arrow">
                <i className="iconsax" data-icon="arrow-up">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.0711 10.3214C17.8811 10.3214 17.6911 10.2514 17.5411 10.1014L12.0011 4.56141L6.46109 10.1014C6.17109 10.3914 5.69109 10.3914 5.40109 10.1014C5.11109 9.81141 5.11109 9.33141 5.40109 9.04141L11.4711 2.97141C11.7611 2.68141 12.2411 2.68141 12.5311 2.97141L18.6011 9.04141C18.8911 9.33141 18.8911 9.81141 18.6011 10.1014C18.4611 10.2514 18.2611 10.3214 18.0711 10.3214Z"
                      fill="#292D32"
                    />
                    <path
                      d="M12 21.2519C11.59 21.2519 11.25 20.9119 11.25 20.5019V3.67188C11.25 3.26188 11.59 2.92188 12 2.92188C12.41 2.92188 12.75 3.26188 12.75 3.67188V20.5019C12.75 20.9119 12.41 21.2519 12 21.2519Z"
                      fill="#292D32"
                    />
                  </svg>
                </i>
              </div>
              Join Us 

            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
