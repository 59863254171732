import React from "react";

export default function FAQ() {
  return (
    <div>
      <section className="faq-section text-start">
        <div className="container">
          <div className="title-basic">
            <h2 className="text-white">FAQs</h2>
          </div>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  data-cursor="pointer"
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
           1. What is BotVerse, and how does it work?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body">
                  <p>
                  BotVerse is an automated trading platform that offers customizable bots to execute trades on your behalf. Users can configure bots based on their trading strategies, and the bots will monitor market data in real time, executing trades across multiple exchanges.

                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  data-cursor="pointer"
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                2. Is BotVerse suitable for beginners?

                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body">
                Yes, BotVerse is designed for both beginners and experienced traders. It features an easy-to-use interface and pre-built trading bots, making it accessible for newcomers, while advanced users can customize bots to fit their trading strategies.

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  data-cursor="pointer"
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                 3. How do I set up a trading bot on BotVerse?

                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body">
                Setting up a bot on BotVerse is simple. You can choose from pre-configured bots or customize one by setting parameters like asset types, trading frequency, and risk levels. The platform provides step-by-step guidance to help you get started.

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  data-cursor="pointer"
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour"
                >
               4. Which exchanges are supported by BotVerse?


                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >
                <div className="accordion-body">
                BotVerse integrates with multiple popular cryptocurrency exchanges, allowing users to trade on several platforms simultaneously. 

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                <button
                  data-cursor="pointer"
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                 5. Are my funds secure with BotVerse?


                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFive"
              >
                <div className="accordion-body">
                BotVerse uses high-level encryption and security measures to protect user data and funds. The platform does not hold custody of your assets; it connects to your exchange accounts via secure APIs, ensuring that your funds remain on the exchange and are not directly accessed by BotVerse.

                </div>
              </div>
            </div>
            {/* <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                <button
                  data-cursor="pointer"
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  Is there a limit to the amount of stuff I can create?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingSix"
              >
                <div className="accordion-body">
                  No, there is no inherent limit to the amount of stuff you can
                  create. The possibilities are virtually limitless.
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
