import React from "react";

export default function Footer2() {
  return (
    <div>
      <footer>
        <div className="container text-center">
          <div className="footer d-flex justify-content-center" style={{paddingBottom:"40px"}}>
            <div className="footer-main">
              <a href="#" className="footer-logo mb-0">
                <img
                  src="assets/images/img/logo.svg"
                  style={{ width: "200px" }}
                  className="img-fluid"
                  alt="logo"
                />
              </a>

              <ul className="social-links mt-3">
                <li>
                  <a data-cursor="pointer" href="https://www.facebook.com/">
                    <img
                      src="assets/images/img/fb.svg"
                      className="img-fluid"
                      alt="facebook"
                    />
                  </a>
                </li>
                <li>
                  <a data-cursor="pointer" href="#">
                    <img
                      src="assets/images/img/telegram.svg"
                      className="img-fluid"
                      alt="linkedin"
                    />
                  </a>
                </li>
                <li>
                  <a data-cursor="pointer" href="https://www.instagram.com/">
                    <img
                      src="assets/images/img/instagram.svg"
                      className="img-fluid"
                      alt="insta"
                    />
                  </a>
                </li>
                <li>
                  <a data-cursor="pointer" href="https://twitter.com/login">
                    <img
                      src="assets/images/img/twitter.svg"
                      className="img-fluid"
                      alt="twitter"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
