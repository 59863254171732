import React from "react";
import Header from "../Coman/Header";
import Sidebar from "../Coman/Sidebar";
import StakeMain from "./PurchaseBot/StakeMain";
import Body from "./Body";

export default function Dashboard() {
  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img px-md-5 px-1">
                <div className="px-md-5 px-1 mt-0 mt-md-5">
                <h2 className="text-white mb-5 text-center">Dashboard </h2>

                  <Body />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
