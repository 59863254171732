import React, { useEffect, useState } from "react";

export default function Hero() {
  const [rotation, setRotation] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const newRotation = scrollTop * 0.1; // Adjust the multiplier as needed
    setRotation(newRotation);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <section className="home-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="home-content">
                <div className="bg-effect">
                  <img
                    src="../assets/images/home/home-bg.gif"
                    className="img-fluid bg-gif"
                    alt=""
                  />
                  <img
                    src="../assets/svg/home/1.png"
                    className="img-fluid effect1 rotate-effect"
                    alt=""
                  />
                  <img
                    src="https://themes.pixelstrap.net/mega_bot/assets/svg/home/2.svg"
                    className="img-fluid effect2 rotate-effect"
                    alt=""
                  />
                </div>
                <div>
                  <h1>
                    Unleash Innovative Trading with
                    <div className="title-effect">
                      <img src="../assets/images/title-effect.png" alt="" />
                      <span>BotVerse</span>
                    </div>{" "}
                  </h1>
                  <p>
                    BotVerse is an automated trading platform designed to
                    enhance your trading experience by providing a wide range of
                    customizable trading bots. By leveraging advanced algorithms
                    and market analysis, BotVerse empowers users to automate
                    their trades, ensuring timely decisions and optimal results
                    without the need for constant monitoring.
                  </p>
                  <h3 className="text-white">Launched on TON
                  </h3>
                  <div className="d-flex justify-content-center">
                    <a
                      href="#"
                      data-cursor="pointer"
                      className="btn-solid btn-absolute text-center mt-3"
                    >
                  Get Started 
                    </a>
                  </div>
                </div>
              </div>
              <div className="home-laptop px-md-0 px-3">
                <div className="laptop-sec position-relative">
                  <div className="hand-sec">
                    <img
                      src="../assets/images/home/hand.png"
                      className="img-fluid left-hand"
                      alt="hand"
                    />
                    <img
                      src="../assets/images/home/hand.png"
                      className="img-fluid right-hand"
                      alt="hand"
                    />
                    <img
                      src="../assets/images/home/finger.png"
                      className="img-fluid left-finger"
                      alt="hand"
                    />
                    <img
                      src="../assets/images/home/finger.png"
                      className="img-fluid right-finger"
                      alt="hand"
                    />
                  </div>
                  <img
                    src="../assets/images/img/hero-image.png"
                    className="img-fluid laptop-img"
                    alt="laptop"
                    style={{ transform: `rotateX(${rotation}deg)` }}
                  />
                </div>
                <div className="home-info">
                  <ul className="info-list">
                    <li>Purchase Bot – Add Liquidity – Start Profiting</li>
                  </ul>
                  <ul className="star-rating">
                    <li>
                      <i className="ri-star-fill" />
                    </li>
                    <li>
                      <i className="ri-star-fill" />
                    </li>
                    <li>
                      <i className="ri-star-fill" />
                    </li>
                    <li>
                      <i className="ri-star-fill" />
                    </li>
                    <li>
                      <i className="ri-star-fill" />
                    </li>
                  </ul>
                  <h4>
                    The simple method to earn passive income by automated
                    trading.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
