import React from 'react'
import Sidebar from '../Coman/Sidebar'
import Header from '../Coman/Header'
import Pagination from '../Coman/Pagination'

export default function RoyaltyRewards() {
  return (
    <>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper">
                <Sidebar />
                <Header />
                <div className="main-chat bg-dash-img px-md-5 px-1">
                  <div className="px-md-5 px-1 mt-0 mt-md-5">
                    <div style={{ overflow: "auto hidden" }}>
                      <h2 className="text-white mb-5 text-center mt-3">
                        Royalty Rewards
                      </h2>
                      <div className="table-main">
                        <div className="tbl-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-black"> Sr.</th>
                                <th className="text-black">
                                  Reward Eligibility
                                </th>
                                <th className="text-black">Stake($)</th>
                                <th className="text-black">Rate</th>
                                <th className="text-black">Datetime</th>
                                <th className="text-black">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="tbl-content">
                          <tbody>

                          </tbody>
                          <tbody />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pages={pages}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
