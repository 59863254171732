import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import Header from "../Coman/Header";
import { ProjectContext } from "../../ProjectContext";
import axios from 'axios'

export default function Profile() {
  const {
    account,
    dbuser,
    connectMetamask, getuserDB
  } = useContext(ProjectContext)

  const [email, setemail] = useState('');
  const [uname, setuname] = useState('');
  const [mobilenumber, setmobilenumber] = useState('');
  const [error, seterror] = useState('');
  const upprofile = async () => {
    if (!account) {
      connectMetamask();
      seterror("Connect first");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "update",
        key: process.env.REACT_APP_KEY,
        address: account,
        uname: uname,
        email: email,
        mobilenumber: mobilenumber
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          seterror(res.data.error);
          return "";
        }
        seterror(res.data.data);
        getuserDB()
      });
  }
  useEffect(() => {
    if(dbuser!==null){
      setemail(dbuser.email)
      setuname(dbuser.uname)
      setmobilenumber(dbuser.mobile_number)
    }
  }, [dbuser])
  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img px-md-5 px-1">
                <h2 className="text-white mb-5 text-center mt-3">
                  Profile
                </h2>

                <div className="row justify-content-center mt-0 mt-md-5 mb-5">
                  <div className="col-lg-5  col-md-10 col-sm-12 col-12 mt-3">
                    <div className="step-1 py-4">
                      <div
                        className="step-text text-white mt-3"
                        style={{ position: "relative", zIndex: "999" }}
                      >
                        <div className="auth-form mx-4">
                          <div className="mb-3 form-group text-start  ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="password" className="form-label">
                                User Name
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter User Name"
                              className="form-control ps-2" value={uname}
                              // onChange={(e) =>
                              //   setuname(e.target.value)
                              // }
                            />
                          </div>
                          <div className="mb-3 form-group text-start  ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="password" className="form-label">
                                Email
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter Email"
                              className="form-control ps-2" value={email}
                              // onChange={(e) =>
                              //   setemail(e.target.value)
                              // }
                            />
                          </div>
                          <div className="mb-3 form-group text-start  ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="password" className="form-label">
                                Mobile Number
                              </label>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter Mobile Number"
                              className="form-control ps-2" value={mobilenumber}
                              // onChange={(e) =>
                              //   setmobilenumber(e.target.value)
                              // }
                            />
                          </div>
                          {error ?
                            <span className="text-danger">{error}</span> : ''}
                          {/* {dbuser ?
                            dbuser.uname === null ?
                              <button className="btn-solid w-100 text-center mt-5" onClick={() => upprofile()}>
                                Update
                              </button> : '' : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
