import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import Dashboard from "./Components/Dashboard/Dashboard";
import Index from "./Components/Home/Index";
import AddLiquidity from "./Components/Dashboard/AddLiquidity/AddLiquidity";
import Team from "./Components/Dashboard/Team/Team";
import PurchaseHistry from "./Components/Dashboard/PurchaseHistry";
import LiquidityHistory from "./Components/Dashboard/LiquidityHistory";
import SponsorRewards from "./Components/Dashboard/TodayProfit/SponsorRewards";
import MobilizationRewards from "./Components/Dashboard/MobilizationRewards";
import SalaryRewards from "./Components/Dashboard/SalaryRewards";
import ActivityRewards from "./Components/Dashboard/ActivityRewards";
import RoyaltyRewards from "./Components/Dashboard/RoyaltyRewards";
import Presentation from "./Components/Dashboard/Presentation";
import PurchaseBot from "./Components/Dashboard/PurchaseBot/PurchaseBot";
import TreeView from "./Components/Dashboard/Tree/TreeView";
import View from "./Components/Dashboard/View";
import StakingRewards from "./Components/Dashboard/StakingRewards";
import Withdrawal from "./Components/Dashboard/Withdrawal";
import Profile from "./Components/Dashboard/Profile";
import ProfilePage from "./Components/Home/ProfilePage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< Index />} />
          <Route path="/login" element={< ProfilePage />} />    
          <Route path="/dashboard" element={< Dashboard />} />
          <Route path="/purchase-bot" element={< PurchaseBot />} />
          <Route path="/addliquidity" element={< AddLiquidity />} />
          <Route path="/purchase-history" element={< PurchaseHistry />} />
          <Route path="/liquidity-history" element={< LiquidityHistory />} />
          <Route path="/team" element={< Team />} />
          <Route path="/tree" element={< TreeView />} />
          <Route path="/sponsor-rewards" element={< SponsorRewards />} />
          <Route path="/mobilization-rewards" element={< MobilizationRewards />} />
          <Route path="/staking-rewards" element={< StakingRewards />} />
          <Route path="/salary-rewards" element={< SalaryRewards />} />
          <Route path="/activity-rewards" element={< ActivityRewards />} />
          <Route path="/royalty-rewards" element={< RoyaltyRewards />} />
          <Route path="/presentation" element={< Presentation />} />
          <Route path="/withdrawal" element={< Withdrawal />} />
          <Route path="/view" element={< View />} />
          <Route path="/profile" element={< Profile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
