import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../ProjectContext";
import axios from 'axios'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header2 from "../Coman/Header2";
import Footer2 from "../Coman/Footer2";

export default function ProfilePage() {
  const {
    account,
    dbuser,
    connectMetamask, getuserDB, toastSuccess, toastError
  } = useContext(ProjectContext)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const link_ref_address = searchParams.get('referral');
  var navigate = useNavigate();

  const [email, setemail] = useState('');
  const [uname, setuname] = useState('');
  const [reff_address, setreff_address] = useState(null);
  const [mobilenumber, setmobilenumber] = useState('');
  const upprofile = async () => {
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "update",
        key: process.env.REACT_APP_KEY,
        address: account,
        uname: uname,
        email: email,
        mobilenumber: mobilenumber,
        ref_address: reff_address
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
        getuserDB()
      });
  }
  useEffect(() => {
    if (dbuser !== null) {
      navigate("/dashboard");
    }
  }, [dbuser])

  useEffect(() => {
    if (dbuser !== null) {
      setemail(dbuser.email)
      setuname(dbuser.uname)
      setmobilenumber(dbuser.mobile_number)
    }
  }, [dbuser])
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreff_address(dbuser.ref_address);
      }
    } else {
      setreff_address(link_ref_address);
    }
  }, [link_ref_address, dbuser, account]);

  return (
    <div>
      <Header2 />
      <section className="pricing-section section-b-space text-start">
        <div className="container">
          <div className="row  mt-0 mt-md-5 mb-5 align-items-center">
            <div className="col-lg-6  col-md-10 col-sm-12 col-12 mt-3">
              <div className="step-1 py-4">
                <div
                  className="step-text text-white mt-3"
                  style={{ position: "relative", zIndex: 999 }}
                >
                  <div className="auth-form mx-4">
                    <div className="mb-3 form-group text-start  ">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password" className="form-label">
                          User Name
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter User Name"
                        className="form-control ps-2" value={uname}
                        onChange={(e) =>
                          setuname(e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3 form-group text-start  ">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password" className="form-label">
                          Email
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        className="form-control ps-2" value={email}
                        onChange={(e) =>
                          setemail(e.target.value)
                        }

                      />
                    </div>
                    <div className="mb-3 form-group text-start  ">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password" className="form-label">
                          Mobile Number
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Mobile Number"
                        className="form-control ps-2" value={mobilenumber}
                        onChange={(e) =>
                          setmobilenumber(e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3 form-group text-start  ">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password" className="form-label">
                          Referral Address
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Referral Address"
                        className="form-control ps-2" value={reff_address}
                        onChange={(e) =>
                          setreff_address(e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3 form-group text-start  ">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password" className="form-label">
                          Address
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-2" value={account}

                      />
                    </div>
                  </div>
                  {/* {error ?
                    <span className="text-danger">{error}</span> : ''} */}
                </div>

              </div>
              <div class="d-flex justify-content-center">
                <button
                  href="#"
                  type="button"
                  className="btn-solid btn-absolute text-center mt-3"
                  onClick={() => upprofile()}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="col-lg-5  col-md-10 col-sm-12 col-12 mt-3 d-flex justify-content-end">
              <div>
                <h2 className="text-white">Unleash the power of automated</h2>
                <p>
                  Select one of the BotVerse BOT from the list below to get
                  started with. Each has its own benefits and capabilities to
                  ensure profitability in live markets. Set your capital
                  deployment and start trading today!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </div>
  );
}
