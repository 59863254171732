import React from "react";

export default function Innovative() {
  return (
    <div>
      <section className="about-section section-b-space section-mb-space text-start">
        <div
          className="bg-effect aos-init aos-animate"
          data-aos="fade-left"
          data-aos-duration={1000}
          data-aos-delay={500}
        />
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-lg-6 order-lg-0 order-1">
              <div className="about-content">
                <div>
                  <div className="title">
                    <span className="number-pattern">01.</span>
                    <h2 className="text-white">
                      BotVerse Unlocks Numerous Benefits For Traders
                    </h2>
                  </div>
                  <p>
                    While making it easy for traders to become profitable,
                    BotVerse is unlocking a number of benefits for traders. Its
                    automated trading ensures that no market opportunities are
                    missed due to human limitations.
                  </p>
                  <ul>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Earn returns on investments without needing to actively
                      manage trades.
                    </li>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Access multiple markets and trading pairs to diversify
                      trading portfolio.
                    </li>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Transparent trading activities where users can trust and
                      verify bot’s operations.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img">
                <img
                 src="assets/images/img/benefits_for_traders.svg"
                  className="img-fluid"
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
