import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Coman/Sidebar'
import Header from '../Coman/Header'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Coman/Pagination';

export default function LiquidityHistory() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "staking",
        submethod: "getbyid",
        // address: "default",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);

  return (
    <>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper">
                <Sidebar />
                <Header />
                <div className="main-chat bg-dash-img px-md-5 px-1">
                  <div className="px-md-5 px-1 mt-0 mt-md-5">
                    <div style={{ overflow: "auto hidden" }}>
                      <h2 className="text-white mb-5 text-center mt-3">
                        Liquidity History
                      </h2>
                      <div className="table-main">
                        <div className="tbl-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-black"> #</th>
                                <th className="text-black">Amount</th>
                                <th className="text-black">Type</th>
                                <th className="text-black">Token</th>
                                <th className="text-black">Rate</th>
                                <th className="text-black">Datetime</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="tbl-content">
                          {isLoading ?
                            <tr className="text-center">
                              <td className="text-center" colSpan={10}>
                                Data is loading
                              </td>
                            </tr> : ""}
                          {!isLoading ? (
                            !data.length ? (
                              <tr className="text-center">
                                <td className="text-center" colSpan={10}>
                                  <span className="w-100">No data found</span>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          <tbody>
                            {data.map((ele, i) => {
                              return (
                                <tr>
                                  <th> {ele.id}</th>
                                  <th>$ {ele.amount} </th>
                                  <th className='text-uppercase'>{ele.token_type}</th>
                                  <th className='text-uppercase'>{ele.token} {ele.token_type}</th>
                                  <th>$ {ele.rate} </th>
                                  <th>{ele.createdAt}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tbody />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pages={pages}
                  />

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
