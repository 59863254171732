import React from 'react'

export default function TON() {
  return (
    <div>
    <section className="about-section section-b-space section-mb-space text-start">
      <div
        className="bg-effect aos-init aos-animate"
        data-aos="fade-left"
        data-aos-duration={1000}
        data-aos-delay={500}
      />
      <div className="container">
        <div className="row g-md-5 g-4 align-items-center">
          <div className="col-lg-6 order-lg-0 order-1">
            <div className="about-content">
              <div>
                <div className="title">
             
                  <h2 className="text-white">
                  Developed & Deployed on TON

                  </h2>
                </div>
                <p>
                BotVerse is officially launched on the The Open Network (TON) blockchain to bring automated trading solutions to the wide TON ecosystem. This integration allows users to leverage the speed, scalability, and security of TON while using BotVerse's advanced trading bots to automate their strategies across supported exchanges. 

                </p>
            
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img">
              <img
               src="assets/images/img/ton.png"
                className="img-fluid"
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
