import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProjectContext } from "../../../ProjectContext";
import axios from "axios";

export default function Liquidity() {
  const {
    account,
    dbuser,
    connectMetamask,
    getudata,
    settings,
    esigner,
    ethers,
    stakcontract,
    getsmcs,
    copyaddress,
    getuserDB,
    formatAddress, tonrate,
    balance,
    tokenrate,
    toastSuccess,
    toastError,
    smuser,
  } = useContext(ProjectContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const link_ref_address = searchParams.get('referral');

  const [referral, setreferral] = useState("");
  const [amount, setamount] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [selected_token, setselected_token] = useState('ton');
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const onDeposit = async () => {
    settrdone(true);

    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (Number(amount) < 100) {
      toastError("Minimum $100 required");
      settrdone(false);
      return;
    }
    if (Number(amount) > balance) {
      toastError("Insufficient Balance");
      settrdone(false);
      // return;
    }
    if (dbuser.t_staking === 0) {
      toastError("Purchase BOT first");
      settrdone(false);
      return;
    }
    // if (!referral) {
    //   toastError("Enter Referral Address");
    //   settrdone(false);
    //   return;
    // }

    // var data = await getudata(referral);
    // if (data.data.error) {
    //   toastError("Invalid Referral Address");
    //   settrdone(false);
    //   return "";
    // }
    let token_contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    let ton_token_contract = new ethers.Contract(
      settings.ton_token,
      JSON.parse(settings.ton_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );
    const tonallowance = await ton_token_contract.allowance(
      account,
      settings.contract
    );
    settrdone(true);
    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await ethers.eth.getGasPrice()
      //   );

      try {
        var stk = await stakcontract.addliquidity(
          ethers.utils.parseEther(`${amount}`),
          selected_token == "usdt" ? "1" : "0"
        );
        const receipt = await stk.wait();
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        getsmcs();
        settrdone(false);
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "staking",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: amount,
            token: total_tokens,
            // rate: selected_token == "usdt" ? tokenrate : tonrate,
            rate: tokenrate,
            token_type: selected_token
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            getuserDB();
            toastSuccess("Transaction done");
          });
      } catch (error) {
        console.log(error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    var t_allowance = 0;
    if (selected_token == "usdt") {
      t_allowance = allowance
    } else {
      t_allowance = tonallowance
    }
    if (Number(ethers.utils.formatEther(t_allowance)) < amount) {
      // if (false) {
      try {
        var approv = null
        if (selected_token == "usdt") {
          approv = await token_contract.approve(
            settings.contract,
            ethers.utils.parseEther("100000000")
          );
        } else {
          approv = await ton_token_contract.approve(
            settings.contract,
            ethers.utils.parseEther("100000000")
          );

        }
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (selected_token === "ton") {
      settotal_tokens(amount / tokenrate);
    } else {
      settotal_tokens(amount);
    }
  }, [amount, selected_token, tokenrate]);

  return (
    <div>
      <div>
        <div className="row justify-content-center mt-0 mt-md-5">
          <div className="col-lg-4  col-md-10 col-sm-12 col-12 mt-3">
            <h2 className="text-white mb-5 text-center">Add Liquidity </h2>

            <div class="step-1 py-4">
              <div
                class="step-text text-white mt-3"
                style={{ position: "relative", zIndex: "999" }}
              >
                <div className="auth-form mx-4">
                  <div className="mb-3 form-group text-start">
                    <label htmlFor="text" className="form-label">
                      Enter Amount
                    </label>

                    <input
                      type="text"
                      placeholder="Enter Amount "
                      className="form-control ps-2"
                      value={amount}
                      onChange={(e) => setamount(e.target.value)}
                      id="text"
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-center gap-5">
                    <div>
                      <input
                        type="radio"
                        id="ton"
                        name="tokens"
                        checked={selected_token === 'ton'}
                        onChange={() => setselected_token("ton")}
                      />
                      &nbsp;
                      <label htmlFor="ton">Ton (BEP20) </label>
                      <br />
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="css"
                        name="tokens"
                        checked={selected_token === 'usdt'}
                        onChange={() => setselected_token("usdt")}
                      />
                      &nbsp; <label htmlFor="css">USDT</label>
                      <br />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mx-3">
                    <h6>Rate</h6>
                    <h6>${selected_token === "usdt" ? 1 : tokenrate}</h6>
                  </div>
                  <div className="d-flex justify-content-between mx-3">
                    <h6 className="text-uppercase">{selected_token}</h6>
                    <h6 className="text-uppercase">{total_tokens} {selected_token}</h6>
                  </div>
                  {trdone ?
                    <img src="/assets/images/loader.gif" alt="loader" style={{ "width": "110px" }} /> :
                    <button class="btn-solid w-100 text-center mt-5" onClick={() => onDeposit()}>
                      Add Liquidity
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
