import React from 'react'
import Header2 from '../Coman/Header2'
import Hero from './Hero'
import Main from './Main'
import Creators from './Creators'
import Innovative from './Innovative'
import Resource from './Resource'
import Footer2 from '../Coman/Footer2'
import Unleash from './Unleash'
import Pricing from './Pricing'
import FAQ from './FAQ'
import Contact from './Contact'
import TON from './TON'

export default function Index() {
  return (
    <div>
        <Header2/>
        <Hero/>
        <Creators/>
        <Innovative/>
        <Main/>
        <Resource/>
        <TON/>
        
        <Unleash/>

        <Pricing/>
        
        <FAQ/>
        <Contact/>

        <Footer2/>
    </div>
  )
}
