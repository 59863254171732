import React from "react";

export default function Resource() {
  return (
    <div>
      <section className="about-section right-version section-b-space section-mb-space text-start">
        <div
          className="bg-effect aos-init aos-animate"
          data-aos="fade-right"
          data-aos-duration={1000}
          data-aos-delay={500}
        />
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-lg-6">
              <div className="about-img">
                <img
                  src="assets/images/img/trading-strategy.svg"
                  style={{width:"85%"}}
                  className="img-fluid"
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <div>
                  <div className="title">
                    <span className="number-pattern">02.</span>
                    <h2>How BotVerse’s Trading Strategy Works</h2>
                  </div>
                  <p>
                    As an automated trading bot, BotVerse employs a multifaceted
                    trading strategy to optimize returns and minimize risks. It
                    trades in the direction of well-established markets with
                    robust liquidity and maximum profit probability.
                  </p>
                  <ul>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Constantly analyzing markets and tracking prices of
                      hundreds of assets!
                    </li>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Quick to launch potential trades & grab the opportunity.
                    </li>
                    <li>
                      <img
                        src="https://themes.pixelstrap.net/mega_bot/assets/svg/tick.svg"
                        className="img-fluid"
                        alt="tick"
                      />
                      Benefiting with the speed of blockchain technology &
                      front-running transactions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
