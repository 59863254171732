import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../ProjectContext";
import { useNavigate } from "react-router-dom";

export default function View() {
  const { toastError, toastSuccess, setaccount } = useContext(ProjectContext);
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async () => {
    if (!oAddr) {
      toastError("Enter address")
      return
    }
    toastSuccess("Viewer activated")
    setaccount(oAddr)
    navigate('/dashboard')
  };


  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <div className="main-chat  px-md-5 px-1">
                <div className=" px-1 ">
                  <h2 className="text-white mb-5 text-center mt-3">View  </h2>
                  <div class="step-1 py-4">
                    <div
                      class="step-text text-white mt-3"
                      style={{ position: "relative", zIndex: "999" }}
                    >
                      <form className="auth-form mx-4">
                        <div className="mb-3 form-group text-start">

                          <label htmlFor="password" className="form-label">
                            Address
                          </label>


                          <input
                            className="form-control ps-2"
                            type="text"
                            placeholder="Enter Address"
                            value={oAddr}
                            onChange={(e) =>
                              setoAddr(e.target.value)
                            }
                          />

                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12"></div>
                    <div className="col-lg-2  col-md-6 col-sm-6 col-12 mt-3">
                      <div class="step-1 py-4" type="button" onClick={() => getDta()}>
                        <div class="step-number-1">View</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}
