import React, { useContext, useEffect, useState } from 'react'
import Sidebar from "../../Coman/Sidebar";
import Header from "../../Coman/Header";
import Tree from 'react-d3-tree';
import axios from 'axios';
import { ProjectContext } from "../../../ProjectContext";

export default function TreeView() {
  const { account, copyaddress } = useContext(ProjectContext)

  const [data, setdata] = useState({ name: "you" });
  const [isLoading, setisLoading] = useState(false);
  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#fff">
        {nodeData.name}
      </text>
    </g>
  );
  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 1,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#32edb1"
      }
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g >
      <foreignObject x="-150" y="-50" width="300" height="480"
        style={{ cursor: 'pointer' }}>
        <div className="tree-main text-center wow fadeIn" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn', backgroundColor: "#0e0e0e" }}>
          <div  >
            <span className='tree-span'><i className="fa fa-user" /></span>
          </div>
          <div >
            <h4 type="button" > <a className=" fs-4" type='button' onClick={() => copyaddress(nodeDatum.address)}>{nodeDatum.name} <i className='fa fa-copy'></i></a></h4>
          </div>
          {nodeDatum.children?.length ?
            <a className=" mt-4" type='button' onClick={toggleNode}><span className='tree-span'><i className="fa fa-angle-down" /></span></a>
            :
            ''}
        </div>

      </foreignObject>
    </g>
  );
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        // address: "0x8c4a69ea09077020c97372078e867b148dd9406b",
        address: account,
        // address: "0xe7b485d74aafb65e8b8135dbcb226bdcdf414877",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, [account]);

  return (
    <div>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper">
                <Sidebar />
                <Header />
                <div className="main-chat">
                  {isLoading ?
                    <div className='text-center'><h2>Loading...</h2></div>
                    :
                    <Tree
                      data={data}
                      orientation="vertical"
                      {...treeConfig}
                      scaleExtent={{ min: 0.1, max: 1 }}
                      isInitialRenderForDataset={true}
                      enableLegacyTransitions={true}
                      renderCustomNodeElement={renderRectSvgNode}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
